import * as React from "react";
import GlobalLayout from "../../layouts/GlobalLayout";

import styled from "styled-components";
import colors from "../../layouts/common/colors";
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import { useLanguage } from '../../hooks/hooks'

const NewsItem = styled.div`
  box-sizing: border-box;
  padding: 0.6em;
  border-bottom: 1px solid ${colors.grey};
  background-color: white;
  display: flex;
  flex-direction: row;
  font-size: 2.5vw;

  &:hover {
    cursor:pointer;
    background-color: ${colors.grey};
  }

  .img-wrapper {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    svg {
      font-size: 35px;
    }
  }

  .content-wrapper {
    margin-left: 1em;
    flex-basis: 70%;
    h1 {
      font-size: 1em;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
    }

    p {
      font-size: 0.9em;
      flex-grow: 1;
      margin: 0;
      color: ${colors.blackish};
    }

    a {
      color: ${colors.blue};
    }
  }
`;

type ReleaseSvgProps = {
  text: string,
  color: string
}

const ReleaseSvg : React.FunctionComponent<ReleaseSvgProps> = ({ text, color }) => {
  return (
    <svg width="350" height="159" viewBox="0 0 350 159" version="1.1" preserveAspectRatio="xMinYMin meet">
      <rect width="350" height="169" fill={color}/>
      <g transform="translate(15, 50)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" height="70" width="70">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M23 12l-2.44-2.78.34-3.68-3.61-.82-1.89-3.18L12 3 8.6 1.54 6.71 4.72l-3.61.81.34 3.68L1 12l2.44 2.78-.34 3.69 3.61.82 1.89 3.18L12 21l3.4 1.46 1.89-3.18 3.61-.82-.34-3.68L23 12zm-10 5h-2v-2h2v2zm0-4h-2V7h2v6z"/>
        </svg>
      </g>
      <text x="95" y="95" fill="#fff"> { text }</text>
    </svg>
  )
}

const LatestNews: React.FunctionComponent = () => {
  const language = useLanguage()

  const data = useStaticQuery(graphql`
    query LatestNews {
      allLatestNewsJson {
        nodes {
          en {
            content
            more
            title
            image
            release
            info
          }
          nl {
            content
            more
            title
            image
            release
            info
          }
        }
      }
    }
  `)

  const content = data.allLatestNewsJson.nodes[0];
  const localContent = content[language] || content["en"];

  const items = localContent.map((item: any, index: number) => {
    //const key = new Date(item.timestamp).getTime().toString()
    const key = `${index}`;
    return (
      <NewsItem key={key} onClick={() => item.more && window.open(item.more, "_blank")}>
        <div className="img-wrapper">
          { item.image && (<img src={item.image} />) }
          { item.release && (<ReleaseSvg text={item.release} color="#FC5914" />)}
          { item.info && (<ReleaseSvg text={item.info} color="#66e399" />)}
        </div>
        <div className="content-wrapper">
          <h1>{item.title}</h1>
          <p>{item.content}</p>
        </div>
      </NewsItem>
    );
  });

  return (
    <GlobalLayout>
      <Helmet>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      </Helmet>
      {items}
    </GlobalLayout>
  );
};

export default LatestNews;
